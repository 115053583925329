<template>
  <v-app id="page404">
    <v-container
      fluid
      fill-height
    >
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="10"
          md="4"
        >
          <h1 class="display-2 primary--text">
            Whoops, 404
          </h1>

          <p>The page you were looking for does not exist</p>

          <v-btn
            color="primary"
            outlined
            @click="$router.go(-1)"
          >
            Go back to the previous page
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'Page404',
};
</script>
